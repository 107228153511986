import PropTypes from "prop-types";
import React, {
    useEffect,
    useContext,
    useState,
    useRef,
    useCallback
} from "react";

import Layout from "../../components/layout";
import Loading from "../../components/loading";
import Pagination from "../../components/pagination";
import Tabs from "../../components/tabs";
import { ErrMsg } from "../../components/message";
import ConfirmBox from "../../components/confirmBox";
import Icon, { ChevronLeft } from "../../components/icon";

import { useSettings } from "../../hooks";
import { CurrentUserContext } from "../../providers/auth";
import Services, { hasAuthError } from "../../services";
import { formatDate, timeFormats } from "../../utils/time";
import UserUtil, { getDataAnalysisLinks } from "../../utils/user";
import Links from "../../utils/links";

import grfStyles from "../../components/grfDashboards.module.css";

const AnalysisImportedPage = () => {
    const [checkCount, setCheckCount] = useState(0);

    const forceCheckSession = () => {
        setCheckCount(checkCount + 1);
    };

    return (
        <Layout
            link={Links.DataAnalysis}
            seoTitle="Imported Data Analysis"
            permissionValidator={UserUtil.hasDataOperatorPrivileges}
            forceCheckSessionCount={checkCount}
            mainDivCss=""
        >
            <AnalysisImported forceCheckSession={forceCheckSession} />
        </Layout>
    );
};

export default AnalysisImportedPage;

const AnalysisImported = ({ forceCheckSession }) => {
    const currentUser = useContext(CurrentUserContext);
    const importDataService = Services(currentUser).importData;

    const [accountName, setAccountName] = useState("");
    const [userProfile, setUserProfile] = useState(null);
    const [links, setLinks] = useState([]);
    const [datasets, setDatasets] = useState(null);
    const [showAll, setShowAll] = useState(false);
    const [showImportForm, setShowImportForm] = useState(false);
    const [compareDataset, setCompareDataset] = useState(null);
    const [showCompare, setShowCompare] = useState(false);
    const [delDataset, setDelDataset] = useState(null);
    const [showConfirmDel, setShowConfirmDel] = useState(false);
    const [delErr, setDelErr] = useState("");
    const [loading, setLoading] = useState(true);

    const handleAuthError = (err) => {
        if (hasAuthError(err)) {
            forceCheckSession();
        }
    };

    const changePage = (curPage) => {
        setDelErr("");
        setLoading(true);
        setDatasets(null);
        const getFunc = showAll
            ? importDataService.getAllImportedDatasets
            : importDataService.getImportedDatasets;
        getFunc(curPage)
            .then((d) => {
                setDatasets(d);
            })
            .catch((err) => {
                handleAuthError(err);
                setDatasets(null);
                console.error(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const deleteDataset = () => {
        importDataService
            .deleteImportedDataset(delDataset.dataset_id)
            .then(() => {
                setShowConfirmDel(false);
                changePage();
            })
            .catch((err) => {
                handleAuthError(err);
                setDelErr(
                    `Cannot delete this dataset${
                        err && err.message && ": " + err.message
                    }.`
                );
                setShowConfirmDel(false);
            });
    };

    useEffect(() => {
        if (!currentUser) {
            return;
        }
        currentUser.getUser().then((u) => {
            if (!u) {
                return;
            }
            setAccountName(u.profile.sub);
            setUserProfile(u.profile);
            setLinks(getDataAnalysisLinks(u.profile, Links.DaImported));
            changePage();
        });
    }, [currentUser]);

    useEffect(() => {
        if (!accountName) {
            return;
        }
        changePage();
    }, [showAll]);

    const DatasetsTable = () => (
        <table className="w-full admin-table">
            <thead>
                <tr>
                    <th style={{ width: showAll ? "30%" : "45%" }}>
                        Dataset Name
                    </th>
                    {showAll && <th style={{ width: "15%" }}>Imported By</th>}
                    <th style={{ width: "15%" }}>Imported At (HKT)</th>
                    <th style={{ width: "40%" }}>Actions</th>
                </tr>
            </thead>
            <tbody className="break-all">
                {datasets.datasets.map((d, idx) => (
                    <tr key={idx}>
                        <td>{d.dataset_name}</td>
                        {showAll && <td>{d.imported_by}</td>}
                        <td>
                            {formatDate(d.imported_at, timeFormats.dayTime)}
                        </td>
                        <td>
                            {d.imported_by === accountName && (
                                <button
                                    className="btn-secondary mt-1 mr-3"
                                    onClick={() => {
                                        setCompareDataset(d);
                                        setShowCompare(true);
                                    }}
                                >
                                    Compare with PAQS Data
                                </button>
                            )}
                            <button
                                className="btn-secondary mt-1"
                                onClick={() => {
                                    setDelErr("");
                                    setDelDataset(d);
                                    setShowConfirmDel(true);
                                }}
                            >
                                Delete
                            </button>
                            {delErr &&
                                delDataset.dataset_id === d.dataset_id && (
                                    <ErrMsg msg={delErr} />
                                )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );

    const ConfirmDel = () => (
        <ConfirmBox
            action={deleteDataset}
            cancelAction={() => setShowConfirmDel(false)}
            deleteBtn={true}
        >
            Are you sure to delete dataset{" "}
            <span className="inline-block font-medium">
                {delDataset.dataset_name}
                {showAll ? ` (imported by ${delDataset.imported_by})` : ""}
            </span>
            ?
        </ConfirmBox>
    );

    return (
        <>
            <div className="px-4 pt-3 lg:px-8">
                <Tabs links={links} />
                {showImportForm && (
                    <ImportForm
                        setShowImportForm={setShowImportForm}
                        importDataService={importDataService}
                        reloadDatasets={changePage}
                        onError={handleAuthError}
                    />
                )}
                <div className={showImportForm || showCompare ? "hidden" : ""}>
                    <div className="title-h2 mt-4">Imported Datasets</div>
                    <div
                        className={
                            "my-3 " +
                            (UserUtil.isSysAdmin(userProfile)
                                ? "flex items-center justify-between"
                                : "text-right")
                        }
                    >
                        {UserUtil.isSysAdmin(userProfile) && (
                            <div className="inline-block">
                                <label>
                                    <input
                                        className="mr-1"
                                        type="checkbox"
                                        checked={showAll}
                                        onChange={() =>
                                            setShowAll((prev) => !prev)
                                        }
                                    />
                                    Show datasets imported by all users
                                </label>
                            </div>
                        )}
                        <button
                            className="btn-primary"
                            onClick={() => {
                                setShowImportForm(true);
                            }}
                        >
                            Import Data
                        </button>
                    </div>
                    {loading ? (
                        <Loading />
                    ) : (
                        datasets && (
                            <>
                                {datasets.datasets.length !== 0 ? (
                                    <DatasetsTable />
                                ) : (
                                    <div className="text-center py-32">
                                        No imported datasets found.
                                    </div>
                                )}
                                <Pagination
                                    curPage={datasets.curr_page}
                                    totalPages={datasets.total_pages}
                                    changePageFunc={changePage}
                                />
                            </>
                        )
                    )}
                </div>
                {showConfirmDel && <ConfirmDel />}
            </div>
            {showCompare && (
                <CompareView
                    datasetId={compareDataset.dataset_id}
                    importDataService={importDataService}
                    setShowCompare={setShowCompare}
                    onError={handleAuthError}
                />
            )}
        </>
    );
};

AnalysisImported.propTypes = {
    forceCheckSession: PropTypes.func.isRequired
};

const ImportForm = ({
    setShowImportForm,
    importDataService,
    reloadDatasets,
    onError
}) => {
    const downloadRef = useRef(null);
    const dataFileRef = useRef(null);

    const [datasetName, setDatasetName] = useState("");
    const [dataFile, setDataFile] = useState(null);
    const [datasetErr, setDatasetErr] = useState({});
    const [showConfirm, setShowConfirm] = useState(false);
    const [importErr, setImportErr] = useState("");
    const [importResult, setImportResult] = useState(null);
    const [downloading, setDownloading] = useState(false);

    const downloadSampleFile = () => {
        setDownloading(true);
        importDataService
            .getSampleFile()
            .then((d) => {
                const url = window.URL.createObjectURL(d);
                downloadRef.current.href = url;
                downloadRef.current.click();
                window.URL.revokeObjectURL(url);
                downloadRef.current.href = "";
            })
            .catch((err) => {
                onError(err);
            })
            .finally(() => {
                setDownloading(false);
            });
    };

    const checkInputErrors = () => {
        let errs = {
            datasetName: (!datasetName && "Dataset name is required.") || "",
            dataFile: (!dataFile && "Data file is required.") || ""
        };
        setDatasetErr(errs);
        return Object.keys(errs).find((k) => errs[k]);
    };

    const confirmImport = () => {
        setImportErr("");
        if (checkInputErrors()) {
            return;
        }
        setShowConfirm(true);
    };

    const importData = () => {
        importDataService
            .importDataset(datasetName, dataFile)
            .then((d) => {
                setDatasetName("");
                setDataFile(null);
                setImportResult(d);
                setShowConfirm(false);
            })
            .catch((err) => {
                if (err && err.code === "INVALID_FILE") {
                    setDatasetErr({
                        dataFile: "Invalid data file: " + err.message
                    });
                } else if (err && err.code === "DUPLICATED_IMPORTED_DATASET") {
                    setDatasetErr({
                        datasetName:
                            "Duplicated dataset name: Please use unique dataset name among all datasets imported by yourself."
                    });
                } else if (
                    err &&
                    ["LIMIT_FILE_SIZE", "CONTENT_TOO_LARGE"].includes(err.code)
                ) {
                    setDatasetErr({
                        dataFile: "Data file size cannot exceed 20MB."
                    });
                } else {
                    setImportErr(
                        `Cannot import data${
                            err && err.message && ": " + err.message
                        }`
                    );
                }
                setShowConfirm(false);
                onError(err);
            });
    };

    const close = () => {
        setShowImportForm(false);
        reloadDatasets();
    };

    const ConfirmImport = () => (
        <ConfirmBox
            action={importData}
            cancelAction={() => setShowConfirm(false)}
        >
            Are you sure to import data from this file as a new dataset "
            {datasetName}"?
        </ConfirmBox>
    );

    return (
        <>
            <div className="title-h2 mt-4">Import Data</div>
            {importResult === null ? (
                <>
                    <div className="mt-4">
                        Import air quality-related data file in the following
                        format as a new dataset for comparisons and analyses
                        with PAQS lamppost sensor data.
                        <ul className="list-disc list-inside mt-2 px-4">
                            <li>
                                Data file must be an XLSX file with file size
                                not exceeding 20MB.
                            </li>
                            <li>
                                Data file must have a column header row with
                                "DATE" and "STATION" as the first 2 columns in
                                the same order.
                            </li>
                            <li>
                                When the air quality-related data is recorded
                                per hour, data file must have "HOUR" as the 3rd
                                column.
                            </li>
                            <li>
                                Remaining columns in data file would be the air
                                quality-related data of the corresponding date
                                &#40;and hour&#41;.
                            </li>
                            <li>
                                All column headers in data file must be unique.
                            </li>
                            <li>
                                Data file may contain rows before the column
                                header row and those rows would be ignored
                                during data import.
                            </li>
                            <li>
                                Unit of all air quality-related data should be
                                μg/m³ in data file except "CO" which should be
                                10μg/m³. "CO" data would be multiplied by 10
                                before imported to PAQS for unit conversion.
                            </li>
                            <li>
                                Date &#40;and hour&#41; in data file should be
                                in Hong Kong time zone.
                            </li>
                        </ul>
                        &#40;You may
                        <button
                            className="btn-secondary mx-1"
                            onClick={downloadSampleFile}
                            disabled={downloading}
                        >
                            Download Sample Data File
                        </button>
                        for reference.&#41;
                    </div>
                    <div className="flex w-3/4 mt-4">
                        <div className="w-1/3 label-r">Data File</div>
                        <div className="w-2/3">
                            <input
                                className="input py-0"
                                ref={dataFileRef}
                                type="file"
                                accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                onChange={(e) => {
                                    const files = e.target.files;
                                    const file =
                                        files && files.length > 0
                                            ? files[0]
                                            : null;
                                    setDataFile(file);
                                }}
                            ></input>
                            <ErrMsg msg={datasetErr.dataFile} />
                        </div>
                    </div>
                    <div className="flex w-3/4 mt-4">
                        <div className="w-1/3 label-r">New Dataset Name</div>
                        <div className="w-2/3">
                            <input
                                className="input"
                                onChange={(e) => {
                                    setDatasetName(e.target.value);
                                }}
                                value={datasetName}
                            ></input>
                            <ErrMsg msg={datasetErr.datasetName} />
                        </div>
                    </div>
                    <div className="flex w-3/4 mt-4 pb-12">
                        <div className="w-1/3"></div>
                        <div className="w-2/3">
                            <button
                                className="btn btn-primary w-1/3 mr-4"
                                onClick={confirmImport}
                            >
                                Import
                            </button>
                            <button
                                className="btn btn-secondary w-1/3"
                                onClick={close}
                            >
                                Cancel
                            </button>
                            <ErrMsg msg={importErr} />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="mt-4">
                        Data file has been imported successfully.
                        <ul className="list-disc list-inside mt-2 px-4">
                            <li>
                                Imported {importResult.data_count} data from row{" "}
                                {importResult.start_row} to row{" "}
                                {importResult.end_row}.
                            </li>
                            <li>
                                Imported data for following stations:
                                <ul className="list-disc list-inside px-6">
                                    {importResult.stations.map((d, i) => (
                                        <li key={`s_${i}`}>{d}</li>
                                    ))}
                                </ul>
                            </li>
                            <li>
                                Imported following air quality-related data:
                                <ul className="list-disc list-inside px-6">
                                    {importResult.data_names.map((d, i) => (
                                        <li key={`a_${i}`}>{d}</li>
                                    ))}
                                </ul>
                            </li>
                            {importResult.skipped_data_rows.length > 0 && (
                                <li>
                                    Skipped these rows due to duplicated time
                                    and station:{" "}
                                    {importResult.skipped_data_rows
                                        .map((d) => String(d))
                                        .join(", ")}
                                </li>
                            )}
                            {importResult.missing_data_rows.length > 0 && (
                                <li>
                                    Skipped non-numeric data in these rows:{" "}
                                    {importResult.missing_data_rows
                                        .map((d) => String(d))
                                        .join(", ")}
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="flex w-3/4 mt-4 pb-12">
                        <div className="w-1/3"></div>
                        <div className="w-2/3">
                            <button
                                className="btn btn-primary w-1/3 mr-4"
                                onClick={() => {
                                    setImportResult(null);
                                }}
                            >
                                Import Another Data File
                            </button>
                            <button
                                className="btn btn-secondary w-1/3"
                                onClick={close}
                            >
                                Back to Imported Datasets
                            </button>
                        </div>
                    </div>
                </>
            )}
            <a
                className="hidden"
                download="sample.xlsx"
                href=""
                ref={downloadRef}
            ></a>
            {showConfirm && <ConfirmImport />}
        </>
    );
};

ImportForm.propTypes = {
    setShowImportForm: PropTypes.func,
    importDataService: PropTypes.object,
    reloadDatasets: PropTypes.func,
    onError: PropTypes.func
};

const CompareView = ({
    datasetId,
    importDataService,
    setShowCompare,
    onError
}) => {
    const { GRF_SAME_ORIGIN, grfDashboard } = useSettings();
    const currentUser = useContext(CurrentUserContext);

    const [dashboardUrl, setDashboardUrl] = useState("");
    const [datasetErr, setDatasetErr] = useState("");
    const [loading, setLoading] = useState(true);

    const updateDashboardUrl = (from, to, dataset_id) => {
        const baseUrl = grfDashboard?.imported?.compare;
        if (!!baseUrl && !!from && !!to) {
            const fromTime = new Date(from);
            const toTime = new Date(to);
            const params = [
                `from=${encodeURIComponent(
                    String(
                        Math.max(
                            fromTime.getTime(),
                            toTime.getTime() - 30 * 24 * 3600 * 1000
                        )
                    )
                )}`,
                `to=${encodeURIComponent(String(toTime.getTime()))}`,
                `var-dataset_id=${encodeURIComponent(String(dataset_id))}`,
                "refresh=1m"
            ];
            setDashboardUrl(baseUrl + "?" + params.join("&"));
        }
    };

    const iframeRef = useCallback((node) => {
        // add event listener to iframe for updating user active status only if same origin
        if (node !== null && GRF_SAME_ORIGIN) {
            const resetIdleTimer = () => {
                if (currentUser) {
                    currentUser.resetIdleTimer();
                }
            };
            node.addEventListener("load", () => {
                [
                    "keydown",
                    "wheel",
                    "DOMMouseScroll",
                    "mousewheel",
                    "mousedown",
                    "touchstart",
                    "MSPointerDown"
                ].forEach((e) => {
                    node.contentWindow.addEventListener(
                        e,
                        resetIdleTimer,
                        false
                    );
                });
            });
        }
    }, []);

    useEffect(() => {
        if (!currentUser || !datasetId) {
            return;
        }
        importDataService
            .getImportedDatasetTimeRange(datasetId)
            .then((d) => {
                updateDashboardUrl(d.from_time, d.to_time, datasetId);
                setLoading(false);
            })
            .catch((err) => {
                if (err && err.code === "OWNERSHIP_FORBIDDEN") {
                    setDatasetErr(
                        "You cannot view dataset imported by others."
                    );
                } else if (err && err.code === "IMPORTED_DATASET_NOT_FOUND") {
                    setDatasetErr(
                        "No corresponding imported dataset is found."
                    );
                } else {
                    setDatasetErr(
                        `Cannot compare imported data with PAQS data${
                            err && err.message && ": " + err.message
                        }`
                    );
                }
                setLoading(false);
                onError(err);
            });
    }, [currentUser, datasetId]);

    return (
        <>
            <div className="px-4 lg:px-8">
                <div className="title-h2 mt-4 flex items-center">
                    <button
                        className="inline-block btn-link pr-2"
                        title="Return to page 'Imported Datasets'"
                        onClick={(e) => {
                            setShowCompare(false);
                        }}
                    >
                        <Icon name={ChevronLeft} width="1.5rem" />
                    </button>
                    Compare with PAQS Data
                </div>
                <ErrMsg msg={datasetErr} />
            </div>
            {loading ? (
                <Loading />
            ) : (
                dashboardUrl && (
                    <div className="bg-gray-grf mt-4 pb-4">
                        <iframe
                            id="iframe"
                            className={grfStyles.grfIframe}
                            src={dashboardUrl}
                            ref={iframeRef}
                        ></iframe>
                    </div>
                )
            )}
        </>
    );
};

CompareView.propTypes = {
    datasetId: PropTypes.number,
    importDataService: PropTypes.object,
    setShowCompare: PropTypes.func,
    onError: PropTypes.func
};
