import PropTypes from "prop-types";
import React from "react";
import Icon, { CaretLeft, CaretRight } from "./icon";

const Pagination = ({ curPage, totalPages, changePageFunc }) => {
    const getPages = (total) => {
        let pages = [];
        for (let i = 1; i <= total; i++) {
            pages.push(i);
        }
        return pages;
    };
    const Pages = () => (
        <div className="mt-4 flex flex-row items-center">
            <div className="flex-1"></div>
            <button
                className="mr-2"
                title="Previous page"
                onClick={() => changePageFunc(curPage - 1)}
                disabled={curPage === 1}
            >
                <Icon name={CaretLeft} width="20px" />
            </button>
            <select
                className="input-inline mr-1"
                value={curPage}
                style={{ width: "120px" }}
                onChange={(e) => changePageFunc(e.target.value)}
            >
                {getPages(totalPages).map((p, idx) => (
                    <option key={idx} value={p}>
                        Page {p}
                    </option>
                ))}
            </select>
            / {totalPages}
            <button
                className="ml-2"
                title="Next page"
                onClick={() => changePageFunc(curPage + 1)}
                disabled={curPage === totalPages}
            >
                <Icon name={CaretRight} width="20px" />
            </button>
            <div className="flex-1"></div>
        </div>
    );
    return <>{totalPages > 1 && <Pages />}</>;
};
Pagination.propTypes = {
    curPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    changePageFunc: PropTypes.func.isRequired
};

export default Pagination;
